import React from "react";

import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import with_mom from "./assets/with_mom.jpg";
import ringPic from "./assets/ringed.jpg";
import zak_face from "./assets/zak_faceShot1.jpg";
import zak_ruby from "./assets/zak_ruby1.jpg";
import zak_working from "./assets/zak_working1.jpg";

import { Footer } from "./components/Footer";

export const Home = () => (
  <div>
    <h2>I'm Zak Hodgson - A Canadian Software Developer!</h2>
    <Container>
      <Row>
        <Col>
          <p>
            Welcome Everyone! I'm a recent graduate from the University of
            Western Ontario's Software Engineering program, and a proud holder
            of the Iron Ring. During my time at Western, I fell in love with the
            process of development. It's incredibly rewarding to watch as your
            projects gradually become better and better.
          </p>
          <p>
            On this site, you can find a variety of information on me. This
            includes my skills, the projects I've worked on, and even some
            details on my personal life. Finally, my contact info and GitHub
            profile are available on the contact page. I hope you enjoy touring
            my website!
          </p>
        </Col>
        <Col>
          <Carousel>
            <Carousel.Item>
              <img style={{ paddingLeft: 150 }} src={zak_face}></img>
            </Carousel.Item>
            <Carousel.Item>
              <img style={{ paddingLeft: 150 }} src={zak_working}></img>
            </Carousel.Item>
            <Carousel.Item>
              <img style={{ paddingLeft: 150 }} src={zak_ruby}></img>
            </Carousel.Item>
            <Carousel.Item>
              <img width={525} height={300} src={with_mom}></img>
            </Carousel.Item>
            <Carousel.Item>
              <img width={525} height={300} src={ringPic}></img>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
    <h2>Work Experience</h2>
    <Container>
      <h3>Tata Consultancy Services</h3>
      <Row>
        <Col>
          <li>
            I have worked for TCS from June 2021, and am still currently working
            for them.
          </li>
          <li>
            Thoughout my time here, I have gained valuable experience working
            for a professional software team, and have come to understand how
            they operate.
          </li>
          <li>
            I have gained further experience working with Jira/JTMF for a wide
            variety of tasks.
          </li>
          <li>
            I have also gained an understanding of automation using Selenium,
            Adobe Experience Manager, and Accessibility requirements.
          </li>
          <li>
            Throughout my time at TCS, I frequently make myself available to
            support late night deployments, which often run from 10PM to as late
            as 4M EST.
          </li>
        </Col>
      </Row>
    </Container>
    <h2>My Skills</h2>
    <Container>
      <Row>
        <Col>
          <h3>Frontend Technologies</h3>
        </Col>
        <Col>
          <h3>Backend Technologies</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <li>HTML/CSS/JS</li>
          <li>React/React Native</li>
          <li>Angular</li>
          <li>Android Studio</li>
          <li>Unity</li>
          <li>Wordpress</li>
        </Col>
        <Col>
          <li>MySQL</li>
          <li>MongoDB</li>
          <li>Node.js</li>
          <li>REST APIs</li>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col>
          <h3>Additional Tools</h3>
        </Col>
        <Col>
          <h3>General Programming</h3>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col>
          <li>Photoshop</li>
          <li>Postman</li>
          <li>Wireshark</li>
          <li>Cloud Technologies</li>
        </Col>
        <Col>
          <li>Java</li>
          <li>C++/C#</li>
          <li>Python</li>
          <li>Agile/SCRUM Methodologies</li>
        </Col>
      </Row>
    </Container>
  </div>
);
