import React from "react";

import Figure from "react-bootstrap/Figure";

import FBLogo from "./assets/fb_logo.png";
import LILogo from "./assets/li_logo.png";
import OLLogo from "./assets/ol_logo.png";
import GMLogo from "./assets/gmail_logo.png";
import GHLogo from "./assets/github_Logo.png";

export const Contact = () => (
  <div>
    <h1>Personal Contact Info</h1>
    <p>
      This website is primarily made to act as a portfolio for potential
      employers. However - if any other developers happen to find this site and
      are looking to acquire some extra manpower, I would highly encourage you
      to contact me! My contact information is below.
    </p>
    <Figure>
      <Figure.Image width={50} height={50} src={OLLogo}></Figure.Image>
    </Figure>
    <a>
      My Outlook address is zhodgson@uwo.ca - This is my prefered email for
      business related matters.
    </a>
    <p></p>
    <Figure>
      <Figure.Image width={50} height={50} src={GMLogo}></Figure.Image>
    </Figure>
    <a>My Gmail address is zakhodgson1@gmail.com</a>
    <p></p>
    <Figure>
      <Figure.Image width={50} height={50} src={FBLogo}></Figure.Image>
    </Figure>
    <a href="https://www.facebook.com/profile.php?id=100010964507291">
      View my FaceBook Profile Here
    </a>
    <p></p>
    <Figure>
      <Figure.Image width={50} height={50} src={LILogo}></Figure.Image>
    </Figure>
    <a href="https://www.linkedin.com/in/zak-hodgson/">
      View my LinkedIn Profile Here
    </a>
    <p></p>
    <Figure>
      <Figure.Image width={50} height={50} src={GHLogo}></Figure.Image>
    </Figure>
    <a href="https://www.linkedin.com/in/zak-hodgson-33a852195/">
      View my GitHub Here
    </a>
  </div>
);
