import React from "react";

import Figure from "react-bootstrap/Figure";
import FigureImage from "react-bootstrap/FigureImage";
import FigureCaption from "react-bootstrap/FigureCaption";
import Nav from "react-bootstrap/Nav";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import unityLogo from "./assets/unityLogo.jpg";
import appDevPic from "./assets/appDevPic.jpg";
import webDevPic from "./assets/webDevPic.jpg";

import { Footer } from "./components/Footer";

export const Projects = () => (
  <div>
    <h1>Browse through my finest creations!</h1>

    <Container>
      <Row>
        <Col>
          <Figure>
            <Figure.Image
              height={300}
              width={400}
              src={unityLogo}
            ></Figure.Image>
          </Figure>
        </Col>
        <Col>
          <h2>Unity Projects</h2>
          <p>
            Unity is known as the ultimate game development platform. As someone
            who loves both gaming and developing, I very quickly took a liking
            to it. Take a look at the games I've worked on!
          </p>
          <Nav.Link href="/projects/unity">
            View my Unity projects here
          </Nav.Link>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col>
          <Figure>
            <Figure.Image
              height={300}
              width={400}
              src={appDevPic}
            ></Figure.Image>
          </Figure>
        </Col>
        <Col>
          <h2>App Projects</h2>
          <p>
            Creating Apps is a lot of fun! Over the years, I have worked on apps
            mainly using Android Studio with Java, or with React Native. It's
            extremely satisfying to see an app slowly come together. Check out
            the apps I've helped create!
          </p>
          <Nav.Link href="/projects/apps">
            View the apps I've worked on here
          </Nav.Link>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col>
          <Figure>
            <Figure.Image
              height={300}
              width={400}
              src={webDevPic}
            ></Figure.Image>
          </Figure>
        </Col>
        <Col>
          <h2>Web Projects</h2>
          <p>
            While this website is easily my finest work web development-wise, I
            have other projects under my belt as well! Take a look at the sites
            I've helped create!
          </p>
          <Nav.Link href="/projects/web">
            View the sites I've worked on here
          </Nav.Link>
        </Col>
      </Row>
    </Container>
  </div>
);
