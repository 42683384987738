import React from "react";

import Figure from "react-bootstrap/Figure";
import FigureImage from "react-bootstrap/FigureImage";
import FigureCaption from "react-bootstrap/FigureCaption";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import reactPic from "./assets/reactBootstrap.PNG";
import zh_logo from "./assets/zh_logo.png";

export const WebProjects = () => (
  <div>
    <h1>Zak Hodgson's Sites</h1>
    <h2>zakhodgson.ca</h2>
    <Container>
      <Row>
        <Col>
          <p>
            Built with React and React-Bootstrap for visual stylization. This
            site functions as a large-scale resume/portfolio and a means to grow
            my brand. I intend to continously expand and improve this site for
            many years to come!
          </p>
          <Figure style={{ paddingTop: 10, paddingLeft: 200 }}>
            <Figure.Image height={150} width={150} src={zh_logo}></Figure.Image>
          </Figure>
        </Col>
        <Col>
          <Figure>
            <Figure.Image
              height={300}
              width={525}
              src={reactPic}
            ></Figure.Image>
          </Figure>
        </Col>
      </Row>
    </Container>
    <h2>Deprecated Sites</h2>
    <p>
      Unfortunately, I have two web projects which I am unable to access at the
      moment. However, I believe it will benefit me to outline them and their
      specs.
    </p>
    <Container>
      <Row>
        <Col>
          <h3>Music Review Site</h3>
          <p>
            A small project where authenticated users can upload "songs" (the
            songs weren't actually uploaded) and rate them on a 1-5 star scale
            along with a comment. It was also possible to rate the reviews, if
            the user was authenticated. Unauthenticated users were simply able
            to surf the site and read the reviews. This was possible through a
            search by keywords feature. There was also admin functionality to
            remove songs and reviews. Solo project.
          </p>
          <p>Features:</p>
          <li>Angular for front-end design</li>
          <li>RESTful API created, Postman used to test</li>
          <li>MongoDB and Node.js used for backend</li>
        </Col>
        <Col>
          <h3>Hospital Database UI</h3>
          <p>
            Another small project made to realize a hospital database design.
            Functions were created in MySQL to operate on the database. The web
            interface was then created using Angular. RESTful API used, with
            Postman for testing. This was a group project.
          </p>
          <p>Functions Included:</p>
          <li>
            getDoctorNByCondition - get number of doctor by specialized
            condition type
          </li>
          <li>
            doctorByCondition/:type - return doctor who specialize in given type
          </li>
          <li>
            findLongWaitCheckIn - return check in that have been waiting for
            more than 1 hour
          </li>
          <li>getFreeRoom - return empty room</li>
          <li>checkin: create check in</li>
          <li>checkin/ - delete check in</li>
        </Col>
      </Row>
    </Container>
  </div>
);
