import React from "react";
import Figure from "react-bootstrap/Figure";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GHL from "../assets/github_Logo.png";
import my_logo from "../assets/zh_logo.png";

//style={{ height: 50 }}

export const Footer = () => (
  <div className="container-fluid bg-dark text-white">
    <div className="row text-center">
      <div className="col-sm ">Made with React and React-Bootstrap</div>
      <div className="col-sm ">Build. Innovate. Inspire.</div>
      <div className="col-sm">
        Copyright © 2021 Zak Hodgson. All rights reserved.
      </div>
    </div>
  </div>
);

{
  /* <Container>
      <Row>
        <Col>Made with React and React-Bootstrap</Col>
        <Col>Copyright © 2021 Zak Hodgson. All rights reserved.</Col>
      </Row>
    </Container> */
}

// style={{ marginTop: 35 }}
