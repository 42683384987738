import React from "react";

import Figure from "react-bootstrap/Figure";
import FigureImage from "react-bootstrap/FigureImage";
import FigureCaption from "react-bootstrap/FigureCaption";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import TRSU from "./assets/trackrSU.PNG";
import TRRS from "./assets/trackrRS.PNG";
import TRST from "./assets/trackrST.PNG";
import TRME from "./assets/trackrME.PNG";
import TRPS from "./assets/trackrPS.PNG";
import TRRF from "./assets/trackrRF.PNG";

import IMSU from "./assets/incr_SU.PNG";
import IMHI from "./assets/incr_HI.PNG";
import IMHS from "./assets/incr_HS.PNG";
import IMHT from "./assets/incr_HT.PNG";
import IMHV from "./assets/incr_HV.PNG";
import IMJS from "./assets/incr_JS.PNG";

import SP1 from "./assets/SP_1.PNG";
import SP2 from "./assets/SP_2.PNG";
import SP3 from "./assets/SP_3.PNG";
import SP4 from "./assets/SP_4.PNG";
import SP5 from "./assets/SP_5.PNG";
import SP6 from "./assets/SP_6.PNG";

import GHL from "./assets/github_Logo.png";
import YTL from "./assets/yt_logo.png";

import hadeel from "./assets/hadeel.jpg";
import nawar from "./assets/nawar.jpg";
import esraa from "./assets/esraa.jpg";
import denys from "./assets/denys.jpg";
import nadeen from "./assets/nadeen.jpg";
import jay from "./assets/jay.jpg";
import josh from "./assets/josh.jpg";

export const AppProjects = () => (
  <div>
    <h1>Zak Hodgson's Apps</h1>
    <h2>TrackR</h2>
    <Container>
      <Row>
        <Col>
          <p>
            TrackR was my capstone project during my final year at Western. Made
            with React-Native, this app is intended to store a user's receipts.
            This is possible through three different methods. The first is
            (read-only) email access. TrackR can access your inbox, scan through
            it, and extract the information needed to store any receipts
            present. This includes E-receipts.
          </p>
        </Col>
        <Col>
          <Figure>
            <Figure.Image src={TRSU}></Figure.Image>
            <Figure.Caption>Sign-up Page</Figure.Caption>
          </Figure>
        </Col>
        <Col>
          <Figure>
            <Figure.Image src={TRRS}></Figure.Image>
            <Figure.Caption>User's Receipt List</Figure.Caption>
          </Figure>
        </Col>
        <Col>
          <Figure>
            <Figure.Image src={TRST}></Figure.Image>
            <Figure.Caption>All Stores from Receipts</Figure.Caption>
          </Figure>
        </Col>
      </Row>

      <Row>
        <Col>
          <p>
            Additionally, by making use of Google's cloud vison API, TrackR is
            able to create receipts from photos. Finally, receipts can be
            created manually by the user as well. To easily find specific
            receipts, users can filter their receipts by store as well as price.
          </p>
        </Col>
        <Col>
          <Figure>
            <Figure.Image src={TRME}></Figure.Image>
            <Figure.Caption>Manual Entry Screen</Figure.Caption>
          </Figure>
        </Col>
        <Col>
          <Figure>
            <Figure.Image src={TRPS}></Figure.Image>
            <Figure.Caption>Extracting Info from Photo</Figure.Caption>
          </Figure>
        </Col>
        <Col>
          <Figure>
            <Figure.Image src={TRRF}></Figure.Image>
            <Figure.Caption>Receipt Filtering Screen</Figure.Caption>
          </Figure>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col>
          <h3>Links</h3>
          <Figure>
            <Figure.Image width={50} height={50} src={GHL}></Figure.Image>
          </Figure>
          <a>Source Code is unavailable at this time.</a>
          <p></p>
          <Figure>
            <Figure.Image width={50} height={50} src={YTL}></Figure.Image>
          </Figure>
          <a href="https://www.youtube.com/watch?v=7EY95RM1BKM">
            View Demo Video
          </a>
        </Col>
        <Col>
          <h3>Collaborators</h3>
          <p>Meet my teammates for TrackR!</p>
          <Figure>
            <Figure.Image width={100} height={100} src={josh}></Figure.Image>
          </Figure>
          <a href="https://joshualarose.ca/">Joshua LaRose</a>
          <Figure>
            <Figure.Image width={100} height={100} src={denys}></Figure.Image>
          </Figure>
          <a href="https://github.com/DenysAshikhin">Denys Ashikhin</a>
        </Col>
      </Row>
    </Container>
    <h2>Incrementum</h2>
    <Container>
      <Row>
        <Col>
          <p>
            Made with Android Studio and Java, Incrementum is an app intended to
            help users break their bad habits. For each of their habits, users
            are expected to track whether or not they engaged each day. This
            results in further progress on the map, which eventually yields
            encouraging messages and trophies. Furthermore, users can log their
            feelings on each habit via journal functionality.
          </p>
        </Col>
        <Col>
          <Figure>
            <Figure.Image src={IMSU}></Figure.Image>
            <Figure.Caption>Sign-up Page</Figure.Caption>
          </Figure>
        </Col>
        <Col>
          <Figure.Image src={IMHS}></Figure.Image>
          <Figure.Caption>User's Habits</Figure.Caption>
        </Col>
        <Col>
          <Figure.Image src={IMHI}></Figure.Image>
          <Figure.Caption>Adding a Habit</Figure.Caption>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            For each habit, triggers would be stored. These triggers would
            affect the messages that users receive, to properly reinforce them
            not to indulge in their habit. Likewise, the time that each habit
            typically occurred altered the notifications the user would receive.
            MongoDB was used as the database for the project. Unit testing
            performed with JUnit.
          </p>
        </Col>
        <Col>
          <Figure>
            <Figure.Image src={IMHT}></Figure.Image>
            <Figure.Caption>User Tracking their Habit</Figure.Caption>
          </Figure>
        </Col>
        <Col>
          <Figure.Image src={IMHV}></Figure.Image>
          <Figure.Caption>Visualization of Progress</Figure.Caption>
        </Col>
        <Col>
          <Figure.Image src={IMJS}></Figure.Image>
          <Figure.Caption>Creating a Journal Entry</Figure.Caption>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col>
          <h3>Links</h3>
          <Figure>
            <Figure.Image width={50} height={50} src={GHL}></Figure.Image>
          </Figure>
          <a href="https://github.com/nelshaw/incrementum">
            View GitHub Repo Here
          </a>
        </Col>
        <Col>
          <h3>Collaborators</h3>
          <p>Meet my teammates for Incrementum!</p>
          <Figure>
            <Figure.Image width={100} height={100} src={nawar}></Figure.Image>
          </Figure>
          <a href="https://www.linkedin.com/in/nawar-aswad-0a56211ab/">
            Nawar Aswad
          </a>
          <Figure>
            <Figure.Image width={100} height={100} src={hadeel}></Figure.Image>
          </Figure>
          <a href="https://www.linkedin.com/in/hadeel-elmadhoon/">
            Hadeel Elmadhoon
          </a>
          <Figure>
            <Figure.Image width={100} height={100} src={esraa}></Figure.Image>
          </Figure>
          <a href="https://www.linkedin.com/in/esraaaltoum/">Esraa Altoum </a>
          <Figure>
            <Figure.Image width={100} height={100} src={nadeen}></Figure.Image>
          </Figure>
          <a href="https://www.linkedin.com/in/nadeenelshawish/">
            Nadeen Elshawish
          </a>
          <Figure>
            <Figure.Image width={101} height={101} src={jay}></Figure.Image>
          </Figure>
          <a href="https://www.linkedin.com/in/jay-lad-889502186/">Jay Lad</a>
        </Col>
      </Row>
    </Container>
    <h2>SmashPractice</h2>
    <Container>
      <Row>
        <Col>
          <p>
            A solo project also made with Android Studio and Java. The Smash
            Brothers series have been some of my favourite games since I was a
            young kid. As a result, I decided to make an app that could
            theoretically be used to grow your skills and knowledge on the
            series' latest installment, Ultimate. The app has three main modes,
            which all allow the user to take notes.
          </p>
        </Col>
        <Col>
          <Figure>
            <Figure.Image src={SP1}></Figure.Image>
            <Figure.Caption>Login Page</Figure.Caption>
          </Figure>
        </Col>
        <Col>
          <Figure.Image src={SP2}></Figure.Image>
          <Figure.Caption>Mode Select</Figure.Caption>
        </Col>
        <Col>
          <Figure.Image src={SP4}></Figure.Image>
          <Figure.Caption>Record Mode</Figure.Caption>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Record Mode is intended to be used while you play Ultimate. Its
            interface easily allows the user to record the details of the game,
            and make corresponding notes. Compare mode allows the user to
            compare two characters stats. Finally, Watch Mode allows the user to
            watch Youtube videos and take notes on them. All notes the user
            makes will be available in their profile, and can be sent to their
            email. MongoDB was used as the database for this app.
          </p>
        </Col>
        <Col>
          <Figure>
            <Figure.Image src={SP3}></Figure.Image>
            <Figure.Caption>Compare Mode</Figure.Caption>
          </Figure>
        </Col>
        <Col>
          <Figure.Image src={SP5}></Figure.Image>
          <Figure.Caption>Watch Mode</Figure.Caption>
        </Col>
        <Col>
          <Figure.Image src={SP6}></Figure.Image>
          <Figure.Caption>Notes Screen</Figure.Caption>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col>
          <h3>Links</h3>
          <Figure>
            <Figure.Image width={50} height={50} src={GHL}></Figure.Image>
          </Figure>
          <a href="https://github.com/zakhodgson1/Smashpractice">
            View GitHub Repo Here
          </a>
          <p></p>
          <Figure>
            <Figure.Image width={50} height={50} src={YTL}></Figure.Image>
          </Figure>
          <a href="https://www.youtube.com/watch?v=eIPyVIfnjFk">
            View Demo Video
          </a>
        </Col>
        <Col>
          <h3>Disclaimers</h3>
          <p>
            - The MongoDB collection for characters is not yet fully populated,
            as the process would be extremely monotonous. However, two
            characters are enough to properly demonstrate the functionality.
          </p>
          <p>
            - Currently, I am having difficulties running this project. As a
            result, these images do not capture the full experience. I am
            working on resolving this issue. However, the video should give a
            strong idea on how the updated app looks.
          </p>
        </Col>
      </Row>
    </Container>
  </div>
);
