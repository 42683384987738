import React from "react";

export const NoMatch = () => (
  <div>
    <h2>Welcome to the Twilight Zone</h2>
    <p>
      You're looking for a page that does not exist. Please navigate back to the
      webpage through the nav bar. If a link in this site has sent you here,
      please contact me and let me know. I will fix it as soon as I can.
    </p>
  </div>
);
