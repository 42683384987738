import React from "react";

import Figure from "react-bootstrap/Figure";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import runPic from "./assets/zak_running.png";

import runes_clip from "./assets/runes_clip.mp4";

export const About = () => (
  <div>
    <h1>Who am I?</h1>
    <p>
      My name is Zak Hodgson. I was born January 2nd, 1999 in the town of
      Leamington, Ontario. I lived there all my life until I began my studies at
      Western in September of 2017. I was a very late bloomer in regards to
      discovering my love for programming. In fact, I had actually never
      programmed before 12th grade. Going into University, I didn't even know
      which type of engineering I wanted. However, the first year course really
      made me fall in love with it and I haven't looked back since.
    </p>
    <h1>My Hobbies</h1>
    <p>
      Nobody can develop all the time! I believe that in order to work hard, you
      have to play hard.
    </p>
    <Container>
      <Row>
        <Col>
          <h2>Athletics</h2>
          <p>
            Pushing your body to its limit is a great way to feel better about
            yourself. With this mentality, It makes sense that distance running
            is easily my favourite way to get exercise. I competed in cross
            country throughout most of high school, and I have no doubt that
            I'll be a runner all throughout my life. In addition, I would also
            consider myself a strong swimmer.
          </p>
          <p>
            Socially, I have a blast playing sports with friends. I particularly
            love basketball! Some of my other favourites include beach
            volleyball, spikeball, and racquet sports.
          </p>

          <Figure>
            <Figure.Image height={300} width={400} src={runPic}></Figure.Image>
            <Figure.Caption>
              A picture of my younger self after a 5k race
            </Figure.Caption>
          </Figure>
        </Col>
        <Col>
          <h2>Gaming</h2>
          <p>
            I have loved gaming since I was a young kid. I view it as a highly
            creative medium and love the mental warfare aspect that can come
            into play! Currently, Chess is my favourite game to play in my free
            time. I play mainly rapid games, and enjoy openings such as the
            London, Italian, Sicilian, and the Scandanavian defense. Fellow
            Chess fans, feel free to add zakhodgson1 on Chess.com! More
            casually, I mostly enjoy playing party games with friends.
          </p>
          <p>
            Furthermore, in the past (late high school - 1st year of University)
            I had a lot of success entering Smash tournaments! These games were
            my favourites as a kid, and when the Wii U installment came out I
            wanted to prove myself. I played almost entirely Diddy Kong in
            tournaments under the tag "Nanaba" (an anagram of Diddy's best tool
            - the banana). During my time playing, I achieved the #1 rank in
            both London and Windsor, and was listed top 30 in Canada at the end
            of the game's competitive lifespan.
          </p>
          <video
            width={600}
            height={400}
            className="videoTag"
            autoPlay
            loop
            muted
          >
            <source src={runes_clip} type="video/mp4" />
          </video>
          <p>
            A cool combo I hit to advance to the finals of a Michigan Tourney.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>Television</h2>
          <p>
            Watching TV is a great way to relax after handling the more
            important matters in your day. I often enjoy watching sports such as
            Basketball, Hockey and the UFC. However, I generally prefer to watch
            shows - check out the list of my favourites below!
          </p>
          <li>Attack On Titan</li>
          <li>Better Call Saul</li>
          <li>Black Mirror</li>
          <li>Breaking Bad</li>
          <li>Code Geass</li>
          <li>Death Note</li>
          <li>How I met your Mother</li>
          <li>The Office</li>
          <li>Sherlock</li>
        </Col>
        <Col>
          <h2>Music</h2>
          <p>
            Music is easily one of the most incredible inventions humanity has
            produced. It can make you feel any way imaginable - from being able
            to pull on your heartstrings, get you excited, or help you relax. In
            the near future, I aspire to make my own electrical/lofi music. But
            for now, you'll have to settle for a list of my favourite artists.
          </p>
          <li>A L E X</li>
          <li>Alvvays</li>
          <li>Anderson.paak</li>
          <li>Beach House</li>
          <li>Gorillaz</li>
          <li>Kanye West</li>
          <li>MF DOOM</li>
          <li>Post Malone</li>
          <li>Tame Impala</li>
        </Col>
      </Row>
    </Container>
  </div>
);
