import React from "react";
import Figure from "react-bootstrap/Figure";
import FigureImage from "react-bootstrap/FigureImage";
import FigureCaption from "react-bootstrap/FigureCaption";
import Nav from "react-bootstrap/Nav";

import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import fightingGamePic from "./assets/fightingGamePic.PNG";
import fightingCSS from "./assets/fightingCSS.PNG";
import fightingAI from "./assets/fightingAI.PNG";
import GW1 from "./assets/Guesswho1.PNG";
import GW2 from "./assets/Guesswho2.PNG";
import GW3 from "./assets/Guesswho3.PNG";
import GW4 from "./assets/guessWho4.PNG";
import GW5 from "./assets/guessWho5.PNG";
import MP1 from "./assets/mp1.PNG";
import MP2 from "./assets/mp2.PNG";
import MP3 from "./assets/mp3.PNG";
import SH1 from "./assets/cell_shader.PNG";
import SH2 from "./assets/underGirl.png";
import SH3 from "./assets/shaderGirl1.JPG";
import SH4 from "./assets/shaderGirl2.JPG";

import GHL from "./assets/github_Logo.png";
import YTL from "./assets/yt_logo.png";
import perceivePic from "./assets/perceive.png";
import josh from "./assets/josh.jpg";

export const UnityProjects = () => (
  <div>
    <h1>Zak Hodgson's Unity Projects</h1>
    <h2>Fighting Game</h2>
    <Container>
      <Row>
        <Col>
          <p>
            A fighting game project originally built for school. This is a
            project I intend to slowly build up over the coming years.
            Currently, the game features four custom-designed fighters with
            unique moves and stats. You can play against an AI player, or with
            two people on the same keyboard.
          </p>
          <Figure>
            <Figure.Image width={50} height={50} src={GHL}></Figure.Image>
          </Figure>
          <a href="https://github.com/zakhodgson1/fightingGame">
            View Github Repo Here
          </a>
        </Col>
        <Col>
          <Carousel>
            <Carousel.Item>
              <img width={525} height={300} src={fightingCSS}></img>
              <Carousel.Caption>
                <h3>Character Select Screen</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img width={525} height={300} src={fightingAI}></img>
              <Carousel.Caption>
                <h3>An example match vs the AI</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img width={525} height={300} src={fightingGamePic}></img>
              <Carousel.Caption>
                <h3>An example 2P match</h3>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
    <h2>Guess Who</h2>
    <Container>
      <Row>
        <Col>
          <p>
            Making use of Mirror (A high level networking API for Unity),
            Top-Down Engine and cloud technologies, Guess Who is a game intended
            to be played in small groups of friends. One player in the group is
            the hunter, and must track down their friends hiding among the
            several AI-controlled NPC's scattered throughout the map. The hunter
            has the power to kill the player nearest to them. If the hunter is
            right, they will gain an extra life (they start with 3). Once the
            hunter's lives hit zero, another player will become the hunter!
            Features a custom-made map.
          </p>
        </Col>
        <Col>
          <Carousel>
            <Carousel.Item>
              <img width={525} height={300} src={GW1}></img>
              <Carousel.Caption>
                <h3>Main Menu Screen</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img width={525} height={300} src={GW2}></img>
              <Carousel.Caption>
                <h3>Server Setup Screen</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img width={525} height={300} src={GW3}></img>
              <Carousel.Caption>
                <h3>Gameplay</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img width={525} height={300} src={GW4}></img>
              <Carousel.Caption>
                <h3>Additional POV</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img width={525} height={300} src={GW5}></img>
              <Carousel.Caption>
                <h3>One of the map's teleporters</h3>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Link</h3>
          <Figure>
            <Figure.Image width={50} height={50} src={GHL}></Figure.Image>
          </Figure>
          <a href="https://github.com/Ibtesam-Mahmood/guessWhoAssets">
            View Github Repo Here
          </a>
        </Col>
        <Col>
          <h3>Collaborators</h3>
          <p>Meet my Teammates for Guess Who!</p>
          <Figure>
            <Figure.Image
              width={100}
              height={100}
              src={perceivePic}
            ></Figure.Image>
          </Figure>
          <a href="http://perceivedev.com/#/">Perceive Dev</a>
          <Figure>
            <Figure.Image width={100} height={100} src={josh}></Figure.Image>
          </Figure>
          <a href="https://joshualarose.ca/">Joshua LaRose</a>
        </Col>
      </Row>
    </Container>
    <h2>"Mind Parasite" Prototype</h2>
    <Container>
      <Row>
        <Col>
          <p>
            A prototype made as a proof of concept. Mind Parasite was designed
            to be a puzzle game similar to portal. The game's primary feature is
            the ability to switch bodies with people who make direct eye
            contact. This prototype contains one level which is designed to
            exemplify the puzzles made possible by this mechanic. SNAPS
            prototype packs were used to design the map, and character models
            were obtained from mixamo. I encourage you to play and enjoy!
          </p>
          <Figure>
            <Figure.Image width={50} height={50} src={YTL}></Figure.Image>
          </Figure>
          <a href="https://www.youtube.com/watch?v=D54Ww8oAYAo">
            View Demo Video
          </a>
        </Col>
        <Col>
          <Carousel>
            <Carousel.Item>
              <img width={525} height={300} src={MP1}></img>
              <Carousel.Caption>
                <h3>Start Area</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img width={525} height={300} src={MP2}></img>
              <Carousel.Caption>
                <h3>Control Room</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img width={525} height={300} src={MP3}></img>
              <Carousel.Caption>
                <h3>Factory Room</h3>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
    <h2>Shaders</h2>
    <Container>
      <Row>
        <Col>
          <p>
            Shaders are incredibly useful for customizing the appearance of any
            in-game object. They are difficult and complex, which serve to make
            a well-done shader all the more rewarding. My current best shaders
            include a customizable cell-shader (you can customize the ranges and
            the colors) that works best on spheres. I tried to make the example
            photo of this shader look like an eye. Additionally, I have a
            gradient mapping shader that I used on a picture of a girl
            underwater. The blue is mapped from the original image onto a
            pink/Sky Blue/Deep Blue gradient. (Hence, these are the only colours
            you see in the altered images.) The two photos differ in terms of
            the intensity of the blue mapped.
          </p>
          <p>More Shaders coming soon!</p>
        </Col>
        <Col>
          <Carousel>
            <Carousel.Item>
              <img width={525} height={300} src={SH1}></img>
            </Carousel.Item>
            <Carousel.Item>
              <img width={525} height={300} src={SH2}></img>
            </Carousel.Item>
            <Carousel.Item>
              <img width={525} height={300} src={SH3}></img>
            </Carousel.Item>
            <Carousel.Item>
              <img width={525} height={300} src={SH4}></img>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
  </div>
);
